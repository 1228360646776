/**
 * 数字人时长商品卡片组件
 */

import { GoodsInfo, OrderMessageData } from '@/types/goods';
import { getElectronData } from '@/helpers/message';

import Style from './DigtalCard.module.less';

interface DigtalCardProps {
  /** 商品数据 */
  dataSource?: GoodsInfo;
  /** 样式类名 */
  className?: string;
}

function DigtalCard(props: DigtalCardProps) {
  const { dataSource } = props;
  const { name, price = 0, originPrice = 0 } = dataSource || {};
  const detailData = getElectronData() as unknown as OrderMessageData;
  const { period = '', timeTip = '' } = detailData || {};

  return (
    <div className={Style.card}>
      <div className={Style.time}>{name}</div>
      <div>
        <span className={Style.price} style={{ WebkitBackgroundClip: 'text' }}>
          ${Math.ceil(price / 100)}
        </span>
        <del className={Style.originPrice}>${originPrice / 100}</del>
      </div>
      <div className={Style.line} />
      <div className={Style.service}>{period}</div>
      <div className={Style.tips}>{timeTip}</div>
    </div>
  );
}

export default DigtalCard;
