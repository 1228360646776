import storage from '@/core/localStorage';
import { OrderMessageData } from '@/types/goods';

/** 缓存electron客户端数据 */
export const saveLocalStorage = () => {
  const detailData =
    window.electronApi?.getMessage() as unknown as OrderMessageData;
  if (detailData) {
    storage.setLocalStorage({
      details: detailData,
    });
  }
};

/** 获取electron客户端数据 */
export const getElectronData = () => {
  const detailData =
    window.electronApi?.getMessage() as unknown as OrderMessageData;
  const localData = storage.getLocalStorage('details');
  return detailData || localData || {};
};
