/**
 * 定制数字人商品卡片组件
 */

import clx from 'classnames';
import { GoodsInfo, OrderMessageData } from '@/types/goods';
import { getElectronData } from '@/helpers/message';

import Style from './CustomCard.module.less';
import { convertDollarToCent, convertDollarToCentWhole } from '@/utils/price';
import { QUANTITY_NUM } from '@/constants/Good';

interface CustomCardProps {
  /** 商品数据 */
  dataSource?: GoodsInfo;
  /** 商品数量 */
  quantity?: number;
  /** 样式类名 */
  className?: string;
}

function CustomCard(props: CustomCardProps) {
  const { dataSource } = props;

  const {
    price = 0,
    terminalDiscount = 0,
    terminalOfferPrice = 0,
    isTerminalOffer = false,
  } = dataSource || {};

  const detailData = getElectronData() as unknown as OrderMessageData;
  const {
    customFigure,
    agentCoupon,
    agentOrderDetail,
    quantity = QUANTITY_NUM,
  } = detailData || {};

  const { title, goodName, unit, quantityText } = customFigure || {};

  /** 优惠折扣限制首单使用 */
  const isFirstOrder = isTerminalOffer && !!terminalDiscount;

  /** 折扣优惠的价格 */
  const discountedPrice = price - terminalOfferPrice;

  /** 渲染价格 */
  const renderPrice = (num: number) => (
    <span className={Style.price}>
      ${convertDollarToCentWhole(price * num)}
    </span>
  );

  /** 渲染折扣订单价格 优惠限制首单 */
  const renderOrderTotal = isFirstOrder ? (
    <div className={Style.order}>
      <div className={Style['order-item']}>
        <span className={Style.label}>{`${agentCoupon}：`}</span>
        <span className={Style.total}>
          -${convertDollarToCent(discountedPrice * quantity)}
        </span>
      </div>
      <div className={Style['order-item']}>
        <span className={Style.label}>{`${agentOrderDetail}：`}</span>
        <span className={Style.total}>{`$${convertDollarToCent(
          terminalOfferPrice * quantity
        )}`}</span>
      </div>
    </div>
  ) : null;

  return (
    <div className={Style['custom-card']}>
      <h4 className={Style.title}>{title}</h4>
      {renderPrice(quantity)}
      <div className={Style.line} />
      <dl className={Style['info-item']}>
        <dt>{goodName}</dt>
        <dd className={Style.desc}>{renderPrice(quantity)}</dd>
      </dl>
      <dl className={Style['info-item']}>
        <dt>
          {quantityText}: {quantity}
        </dt>
        <dd className={clx(Style.desc, Style.unit)}>
          {renderPrice(QUANTITY_NUM)}/{unit}
        </dd>
      </dl>
      {renderOrderTotal}
    </div>
  );
}

export default CustomCard;
