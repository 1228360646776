/** stripe支付错误类型 StripeErrorType */
export enum StripeErrorTypeEnum {
  /** 校验失败 */
  VALID_ERROR = 'validation_error',
}

/** 支付结果 */
export enum StripePayIntent {
  SUCCESS = 'succeeded',
  ERROR = 'error',
  FAIl = 'failed',
}
