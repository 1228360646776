/**
 * 金额换算分转元 向上取整
 * @param amount 金额
 */
export const convertDollarToCentWhole = (amount: number): number =>
  Math.ceil(Number(amount) / 100);

/**
 * 金额换算分转元保留两位小数
 * @param amount 金额
 */
export const convertDollarToCent = (amount: number): string =>
  (Number(amount) / 100).toFixed(2);
