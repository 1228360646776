import service, { FullResponse } from '@bhb-frontend/utils/lib/request';
import md5 from 'md5';
import { Toast } from '@bhb-frontend/lithe-ui/lib';
import { BASE_URL_MAP, CONTEXT_ENV } from '@/config';
import { getElectronData } from '@/helpers/message';

const ENV = (import.meta.env.MODE || CONTEXT_ENV.dev) as CONTEXT_ENV;

export const baseUrl: string =
  BASE_URL_MAP[ENV] || BASE_URL_MAP[CONTEXT_ENV.dev];

/**  service基础配置 */
service.setGlobalConfig({
  baseUrl,
  timeout: 30000, // 请求超时时间
});

service.interceptors.request(config => {
  if (!config.headers) return config;
  const date = Date.now();

  // 跨域
  config.headers['Access-Control-Allow-Origin'] = '*';

  // 客户端信息
  const bhbClientInfo = {
    platform: 'windows', // 应用平台 ios,android,web,wechat,windows
    appVersion: '1.0.0',
    source: 'aiLive', // 非必传，默认mtv：闪剪｜ aiLive：智播
    // deviceId: String, // 设备唯一标识符
    // osVersion: String // 系统版本， android例如：9.0.0
    requestTime: date, // 请求时间，发起请求的时候拿到的客户端时间
  };
  config.headers['bhb-client-info'] = JSON.stringify(bhbClientInfo);
  // config.headers['bhb-channel-host'] = window.location.origin;

  if (ENV !== CONTEXT_ENV.dev && ENV !== CONTEXT_ENV.test) {
    let origin: string = (window as any).location.origin;
    if (origin.indexOf('http') < 0) {
      origin = '';
    }
  }

  config.headers['bhb-api-auth'] = md5(`iW7SfbJ12jpM2NfM0ZdEs0${date}`);

  // 携带token
  const { token = '' } = getElectronData();

  if (token) {
    config.headers['bhb-session-token'] = token;
  }

  return config;
});

// response interceptor
service.interceptors.response((response: FullResponse) => {
  const { error } = response.body;
  if (error && error !== 0) {
    // 统一提示
    Toast.error(response.body.message);
  }
  return response;
});

export default service;
