/**
 * 商品卡片组件
 */

import clx from 'classnames';
import { useMemo } from 'react';
import { GoodsLevelType } from '@/constants/Good';
import { GoodsInfo } from '@/types/goods';
import { getElectronData } from '@/helpers/message';
import { convertDollarToCent, convertDollarToCentWhole } from '@/utils/price';

import Style from './GoodCard.module.less';
import { LocaleMap, LocaleType } from '@/constants/Config';

interface GoodCardProps {
  /** 商品数据 */
  dataSource: GoodsInfo;
  /** 商品类型名称 */
  goodTypeText?: string;
  /** 样式类名 */
  className?: string;
}

function GoodCard(props: GoodCardProps) {
  const { dataSource, goodTypeText, className } = props;
  const {
    personageType: type,
    price,
    remark,
    serviceUnit,
    goodsDescriptions,
    terminalDiscount = 0,
    terminalOfferPrice = 0,
    isTerminalOffer = false,
  } = dataSource || {};

  const { agentCoupon, agentOrderDetail, language } = getElectronData();

  /** 优惠折扣限制首单使用 */
  const isFirstOrder = isTerminalOffer && !!terminalDiscount;

  /** 折扣优惠的价格 */
  const discountedPrice = price - terminalOfferPrice;

  /** 获取当前语言商品描述 */
  const languageGoodsDesc = useMemo(() => {
    /** 系统选择的语言 */
    let currentDesc = goodsDescriptions?.find(
      i => i.language === language
    )?.desc;
    /** 不匹配语言默认英文版 如果没有默认配置第一项描述数据 */
    if (!currentDesc) {
      const englishDesc = goodsDescriptions?.find(
        i => i.language === (LocaleMap.EN as LocaleType)
      )?.desc;
      currentDesc = englishDesc || goodsDescriptions?.[0]?.desc;
    }
    return currentDesc;
  }, []);

  /** 权益列表 */
  const renderRightsList = (texts: string, disable?: boolean) => (
    <ul className={Style['remark-list']}>
      {texts?.split('\n').map(
        text =>
          !!text && (
            <li
              key={`key-${text}`}
              className={clx(Style.item, disable && Style['item-disable'])}
            >
              <i
                className={clx(
                  Style.icon,
                  'iconfont icon-common_icon_close',
                  disable && 'icon-voice_icon_delete'
                )}
              />
              <span className={Style.text}>{text}</span>
            </li>
          )
      )}
    </ul>
  );

  /** 渲染折扣订单价格 优惠限制首单 */
  const renderOrderTotal = () =>
    isFirstOrder ? (
      <div className={Style.order}>
        <div className={Style['order-item']}>
          <span className={Style.label}>{`${agentCoupon}：`}</span>
          <span className={Style.total}>
            -${convertDollarToCent(discountedPrice)}
          </span>
        </div>
        <div className={Style['order-item']}>
          <span className={Style.label}>{`${agentOrderDetail}：`}</span>
          <span className={Style.total}>{`$${convertDollarToCent(
            terminalOfferPrice
          )}`}</span>
        </div>
      </div>
    ) : null;

  /** 渲染价格 */
  const renderPrice = (
    <div className={Style.price}>
      ${convertDollarToCentWhole(price)}
      <span className={Style.unit}>/{serviceUnit}</span>
    </div>
  );

  return (
    <div
      className={clx(
        Style['good-card'],
        type === GoodsLevelType.BASIC && Style['type-basic'],
        type === GoodsLevelType.PRO && Style['type-pro'],
        type === GoodsLevelType.ULTRA && Style['type-ultra'],
        className
      )}
    >
      {type && <span className={Style.tag}>{goodTypeText}</span>}
      {price && renderPrice}
      <div className={Style['operate-box']}>
        <div className={Style.line} />
      </div>
      <section
        className={clx(
          Style['list-wrapper'],
          isFirstOrder && Style['list-wrapper-discount']
        )}
      >
        {renderRightsList(languageGoodsDesc || remark)}
      </section>
      {renderOrderTotal()}
    </div>
  );
}

export default GoodCard;
