import request from '@/api/init';
import { DetailResponse } from '@/types/api';
import {
  PaySecretKey,
  StripeOrder,
  StripeOrderResponse,
  SubscriptionDetail,
} from '@/types/order';

/** 获取stripe公用秘钥 */
export function getStripePublicKey(): Promise<DetailResponse<PaySecretKey>> {
  return request.get('/ai_live/stripe/public/key');
}

/** 创建stripe订单 */
export function createStripeOrder(
  params: StripeOrder
): Promise<DetailResponse<StripeOrderResponse>> {
  return request.post('/ai_live/stripe/order', params);
}

/** 获取 订阅详情 */
export function getSubscriptionDetail(
  query: StripeOrder
): Promise<DetailResponse<SubscriptionDetail>> {
  return request.get('/ai_live/stripe/subscription/detail', query);
}
