/** 语言包 */
export const LocaleMap = {
  ZH: 'zh',
  EN: 'en',
  /** 印度尼西亚语 */
  ID: 'id',
  ZH_TW: 'zh_tw',
  /** 泰语 */
  TH: 'th',
  /** 越南语 */
  VI: 'vi',
} as const;

export type LocaleType = keyof typeof LocaleMap;
