import React from 'react';
import ReactDOM from 'react-dom/client';
import { AliveScope } from 'react-activation';
import App from './App';
import './reset.less';
import './index.less';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AliveScope>
    <App />
  </AliveScope>
);
