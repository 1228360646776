/** 商品默认数量 */
export const QUANTITY_NUM = 1;

/** 商品类型 */
export enum GoodsLevelType {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  ULTRA = 'ultra',
  /** 算力商品 - 表示普通算力包 */
  CREDIT = 'commonCredit',
}

/** 前端弹窗商品类型 */
export enum ModalGoodsType {
  /** 数字人时长类型商品 */
  CREDIT = 'digital',
  /** 购买会员商品 */
  VIP = 'vip',
  /** 定制数字人 */
  CUSTOM = 'customFigure',
}

/** 服务端购买商品类型 */
export enum GoodsType {
  /** 表示普通算力包商品(积分) */
  CREDIT = 'computePower',
  /** 购买会员商品 */
  VIP = 'member',
  /** 定制数字人 */
  CUSTOM = 'customFigure',
}

/** 会员商品权益类型 */
export enum MemberGoods {
  /** 数字人 */
  FIGURE = 'figureLive',
  /** AI配音 */
  DUBBING = 'aiDubbing',
  /** AI脚本 */
  AI_SCRIPT = 'aiScript',
  /** 直播 */
  LIVE = 'live',
}

/** 商品订阅状态 */
export enum GoodStatus {
  /** 取消 */
  CANCEL = 'canceled',
  /** 生效中 */
  ACTIVE = 'active',
  /** 过期失效 */
  EXPIRED = 'failure_expired',
  /** 未完成 */
  INCOMPLETE = 'incomplete',
}
