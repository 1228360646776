/**
 * Stripe第三方支付组件
 */

import React, { useState, useEffect } from 'react';
import {
  loadStripe,
  StripeElementLocale,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Spin } from '@bhb-frontend/lithe-ui/lib';
import {
  createStripeOrder,
  getStripePublicKey,
  getSubscriptionDetail,
} from '@/api/order';
import CheckoutForm from './CheckoutForm';

import Style from './StripePayment.module.less';
import { GoodStatus, QUANTITY_NUM } from '@/constants/Good';
import { GoodsInfo } from '@/types/goods';
import { APPEARANCE_STYLE } from '@/constants/Appearance';

interface StripePayProps {
  /** 商品信息 */
  goodDetail?: GoodsInfo;
  /** 商品数量 */
  quantity?: number;
  /** 语言 */
  locale?: StripeElementLocale;
  /** 支付组件外层样式 */
  className?: string;
  /** 支付表单样式 */
  formClassName?: string;
  /** 成功操作 */
  onSuccess?: () => void;
}

export default function StripePay(props: StripePayProps) {
  const {
    goodDetail,
    quantity,
    className,
    formClassName,
    locale = '',
    onSuccess,
  } = props;
  const {
    id: goodsId = '',
    terminalDiscount = 0,
    terminalOfferPrice = 0,
    isSubscriber = false,
    isTerminalOffer = false,
  } = goodDetail || {};

  /** 订单id */
  const [orderId, setOrderId] = useState('');
  /** 加载中 */
  const [loading, setLoading] = useState(false);
  /** 公钥 */
  const [publicKey, setPublicKey] = useState('');
  /** 客户端密钥 */
  const [clientSecret, setClientSecret] = useState('');

  /** 加载Stripe */
  const stripePromise = loadStripe(publicKey);

  useEffect(() => {
    getPublicKey();
    isSubscriber ? getSubscribeStatus() : createStripePayment();
  }, []);

  /** 获取公钥 */
  const getPublicKey = async () => {
    try {
      const {
        data: { publicKey },
      } = await getStripePublicKey();
      setPublicKey(publicKey);
    } catch (err) {
      console.error(err);
    }
  };

  /** 获取商品订阅详情 先判断是否创建过连续订阅有则用当前订单客户密钥 */
  const getSubscribeStatus = async () => {
    try {
      const {
        data: { status, orderId, clientSecret },
      } = await getSubscriptionDetail({ goodsId });
      if (status === GoodStatus.INCOMPLETE) {
        clientSecret && setClientSecret(clientSecret);
        orderId && setOrderId(orderId);
        setLoading(false);
      } else {
        createStripePayment();
      }
    } catch (err) {
      // 失败重新创建新的订单
      createStripePayment();
      console.error(err);
    }
  };

  /** 创建支付页面  订阅模式与非订阅模式 */
  const createStripePayment = async () => {
    try {
      const orderParmas = {
        goodsId,
        // 带有终端优惠价格为代理商品
        isAgency: !!terminalOfferPrice,
        ...(isTerminalOffer ? { terminalDiscount } : {}),
        quantity: quantity || QUANTITY_NUM,
      };
      const { data: { clientSecret = '', orderId = '' } = {} } =
        await createStripeOrder(orderParmas);
      setClientSecret(clientSecret);
      setOrderId(orderId);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  /** 支付组件配置 */
  const options: StripeElementsOptions = {
    // 客户端密钥
    clientSecret,
    // 外观主题样式
    appearance: APPEARANCE_STYLE,
    // 语言类型
    locale: locale || 'auto',
  };

  return (
    <div className={(Style['stripe-element-wrapper'], className)}>
      {!!loading && (
        <div className={Style.loading}>
          <Spin spinning={!!loading} />
        </div>
      )}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            className={formClassName}
            onSuccess={onSuccess}
            orderId={orderId}
          />
        </Elements>
      )}
    </div>
  );
}
