/** 代码环境上下文 */
export enum CONTEXT_ENV {
  dev = 'dev',
  test = 'test',
  gray = 'gray',
  prod = 'prod',
}

/** 接口域名 */
export const BASE_URL_MAP = {
  dev: 'https://mtv-i18n-api.test.bhbapp.cn', // 本地开发
  test: 'https://mtv-i18n-api.test.bhbapp.cn', // 测试环境
  gray: 'https://mtv-i18n-api.sg.gray.bhbapp.cn', // 预发布环境
  prod: 'https://api.boltcut.ai', // 正式环境
};

/** 支付域名映射 */
export const HOST_MAP = {
  dev: 'https://mtv-i18n-boltcut-pay.test.bhbapp.cn/', // 本地开发
  test: 'https://mtv-i18n-boltcut-pay.test.bhbapp.cn/', // 测试环境
  gray: 'https://mtv-i18n-boltcut-pay.sg.gray.bhbapp.cn/', // 预发布环境
  prod: 'https://pay.bocalive.ai/', // 正式环境
};

/**
 * 新增数字人介绍视频
 */
export const DIGITAL_INTRO_VIDEO =
  'https://bhb-frontend.bhbcdn.com/static/files/7d261c86704f46e8c4d195115e3655b2.mp4';
