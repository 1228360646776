/**
 * stripe 结算表单组件
 */

import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import clx from 'classnames';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Spin, Toast } from '@bhb-frontend/lithe-ui/lib';
import { StripeErrorTypeEnum, StripePayIntent } from '@/constants/Pay';
import { GoodsLevelType, GoodsType, ModalGoodsType } from '@/constants/Good';
import { OrderMessageData } from '@/types/goods';
import { CONTEXT_ENV, HOST_MAP } from '@/config';
import { getElectronData } from '@/helpers/message';

import Style from './CheckoutForm.module.less';

const ENV = (import.meta.env.MODE || CONTEXT_ENV.dev) as CONTEXT_ENV;

interface CheckoutFormProps {
  /** 样式 */
  className?: string;
  /** 订单id */
  orderId?: string;
  /** 支付成功 */
  onSuccess?: () => void;
}

function CheckoutForm(props: CheckoutFormProps) {
  const { orderId, className, onSuccess } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const detailData = getElectronData() as unknown as OrderMessageData;

  const isUltra = detailData.dataSource.personageType === GoodsLevelType.ULTRA;

  /** 是否定制数字人 */
  const isCustom = detailData.dataSource.goodsType === GoodsType.CUSTOM;

  const {
    goodType = ModalGoodsType.VIP,
    payBtnText = '',
    payErrorText = '',
  } = detailData || {};

  /** 是否vip商品购买订单 */
  const isVipOrder = goodType === ModalGoodsType.VIP;

  /** 提交表单支付 */
  const handleSubmit = async e => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    // stripe提交确认支付
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // 返回授权后客户应重定向的URL
        return_url: `${HOST_MAP[ENV]}?orderId=${orderId}&goodType=${goodType}`,
      },
      redirect: 'if_required',
    });

    // 成功状态的处理逻辑
    if (error) {
      // 处理错误情况
      if (error.type !== StripeErrorTypeEnum.VALID_ERROR) {
        // 支付失败提示
        Toast.error(payErrorText);
      }
      window.electronApi?.sendMessage({
        goodType,
        paymentIntentStatus: 'error',
      });
      console.error(error?.message || '');
    } else if (
      paymentIntent &&
      paymentIntent.status === StripePayIntent.SUCCESS
    ) {
      /** 支付成功重新获取用户数据  */
      setTimeout(() => {
        onSuccess?.();
        window.electronApi?.sendMessage({
          goodType,
          orderId,
          paymentIntentStatus: paymentIntent.status,
        });
      }, 1000);
    } else {
      Toast.error(payErrorText);
      console.error('Payment failed');
    }
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div className={clx(Style['pay-form-wrapper'], className)}>
      <form
        className={clx(Style['pay-form'])}
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <div
          className={clx(
            Style['pay-element'],
            isVipOrder && Style['pay-vip-element']
          )}
        >
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
        </div>
        {elements && (
          <button
            className={clx(Style['pay-btn'], {
              [Style['golden-btn-color']]: !isUltra,
              [Style['purple-btn-color']]: isUltra,
              [Style['red-btn-color']]: isCustom,
            })}
            disabled={isLoading || !stripe || !elements}
            id="submit"
            type="submit"
          >
            {isLoading ? (
              <Spin className={Style.spin} spinning={isLoading} />
            ) : (
              payBtnText
            )}
          </button>
        )}
      </form>
    </div>
  );
}

export default CheckoutForm;
