/**
 * 订单购买
 */

import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { StripeElementLocale } from '@stripe/stripe-js';
import { GoodsType, ModalGoodsType, QUANTITY_NUM } from '@/constants/Good';
import { getElectronData, saveLocalStorage } from '@/helpers/message';
import StripePayment from '../StripePayment';
import Style from './OrderElement.module.less';
import DigtalCard from '../DigtalCard';
import GoodCard from '../GoodCard';
import CustomCard from '../CustomCard';

export interface Payload {
  /** 商品id */
  id?: string;
  /** 弹窗是否显示 */
  visible?: boolean;
  /** 弹窗宽度 */
  width?: number;
  /** 弹窗高度 */
  height?: number;
  /** 弹窗标题 */
  modalTitle?: string;
  /** 支付成功 */
  onSuccess?: () => void;
  /** 支付失败 */
  onFail?: () => void;
  /** 返回 */
  onBack?: () => void;
}

const OrderElement = (props: Payload) => {
  const { onSuccess, onBack } = props;

  useEffect(() => {
    saveLocalStorage();
    getPaySucceeded();
  }, []);

  const {
    title = '',
    subTitle = '',
    subDesc = '',
    bottomText = '',
    goodTypeText = '',
    locale = '',
    isFullScreen = false,
    goodType = ModalGoodsType.VIP,
    quantity = QUANTITY_NUM,
    dataSource,
  } = getElectronData();

  /** 是否vip商品购买订单 */
  const isVipOrder = dataSource?.goodsType !== GoodsType.CREDIT;

  /** 支付成功回调地址 （需要重定向类型链接增加orderId参数判断成功） */
  const getPaySucceeded = async () => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('orderId');
    const goodTypeParam = params.get('goodType');
    // 支付状态
    const payStatus = params.get('redirect_status');
    if (orderId) {
      window.electronApi?.sendMessage({
        orderId,
        goodType: goodTypeParam,
        paymentIntentStatus: payStatus,
      });
    }
  };

  /** 处理支付成功回调 */
  const handleSuccess = () => {
    onSuccess?.();
  };

  const handleBack = () => {
    window.electronApi?.sendMessage({
      back: true,
      goodType,
    });
    onBack?.();
  };

  /** 渲染顶部 */
  const renderHeader = useMemo(
    () => (
      <div className={Style.header}>
        <div className={Style.backIcon} onClick={handleBack}>
          <i className="iconfont icon-a-login_icon_return" />
        </div>
        <div className={Style.title}>{title}</div>
      </div>
    ),
    []
  );

  const renderVipHeader = useMemo(
    () => (
      <div className={Style.header}>
        <i
          className={classNames(
            Style.backIcon,
            'iconfont icon-a-login_icon_return'
          )}
          onClick={handleBack}
        />
        <div className={Style.title}>{title}</div>
        {isFullScreen && (
          <p className={Style.desc}>
            {subTitle}
            <br />
            {subDesc}
          </p>
        )}
      </div>
    ),
    []
  );

  /** 渲染左侧商品卡片 */
  const renderGoodCard = useCallback(
    (type: GoodsType) => {
      switch (type) {
        case GoodsType.CREDIT:
          return <DigtalCard dataSource={dataSource} />;
        case GoodsType.CUSTOM:
          return <CustomCard dataSource={dataSource} quantity={quantity} />;
        default:
          return (
            <GoodCard dataSource={dataSource} goodTypeText={goodTypeText} />
          );
      }
    },
    [dataSource, quantity]
  );

  /** 渲染商品支付内容 */
  const renderPayContent = (
    <section className={Style.content}>
      <div className={Style['left-box']}>
        {renderGoodCard(dataSource?.goodsType)}
      </div>
      <div className={Style['right-box']}>
        {dataSource?.id && (
          <StripePayment
            formClassName={Style['checkout-form']}
            locale={locale as StripeElementLocale}
            quantity={quantity}
            goodDetail={dataSource}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    </section>
  );

  /** 渲染底部描述 */
  const renderBottom = useMemo(
    () => isVipOrder && <p className={Style['bottom-text']}>{bottomText}</p>,
    []
  );

  return (
    <div
      className={classNames(
        isVipOrder ? Style['vip-modal'] : Style['custom-pay-modal'],
        isVipOrder && !isFullScreen && Style['vip-modal-small']
      )}
    >
      {isVipOrder ? renderVipHeader : renderHeader}
      {renderPayContent}
      {renderBottom}
    </div>
  );
};

export default OrderElement;
