import { Appearance } from '@stripe/stripe-js';

/** 主题样式 */
export const APPEARANCE_STYLE: Appearance = {
  theme: 'flat',
  variables: {
    colorPrimary: '#E6E6E6',
    colorBackground: '#39393A',
    colorTextPlaceholder: '#808080',
    colorText: '#E6E6E6',
    colorDanger: '#df1b41',
    colorIconTab: 'white',
    colorIconTabSelected: '#fff',
    colorLogo: 'dark',
    spacingUnit: '4px',
    borderRadius: '4px',
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
    },
    '.Tab': {
      color: '#FFFFFF',
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px #FFB663',
      borderImage:
        'linear-gradient(120deg, rgba(255, 236, 193, 1), rgba(255, 182, 99, 1)) 1 1',
      backgroundColor: '#39393A',
      color: '#FFFFFF',
    },
    '.Label': {
      margin: '18px 0 12px 0',
    },
    '.Input': {
      padding: '12px',
    },
    '.Input:hover': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px #626262',
      borderColor: '#626262',
    },
    '.Input:focus': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px #ffffff',
      borderColor: '#ffffff',
    },
  },
};
