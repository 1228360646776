class LocalStorage {
  namespace: string;

  constructor(namespace?: string) {
    this.namespace = namespace || 'digitalLive';
  }

  /** 设置 */
  public setLocalStorage(param: Record<string, any>) {
    const content = JSON.parse(localStorage.getItem(this.namespace) || '{}');
    const newContent = {
      ...content,
      ...param,
    };
    localStorage.setItem(this.namespace, JSON.stringify(newContent));
  }

  /** 获取 */
  public getLocalStorage(key?: string) {
    const content = JSON.parse(localStorage.getItem(this.namespace) || '{}');
    if (key) return content[key];
    return content;
  }

  /** 移除 */
  public remove(key: string) {
    const content = this.getLocalStorage();
    Reflect.deleteProperty(content, key);
    localStorage.setItem(this.namespace, JSON.stringify(content));
  }
}

const storage = new LocalStorage();

export default storage;
